.App {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.Info {
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Info img {
  width: 80%;
  height: 80%;
}

.Info span {
  height: 20%;
  font-size: 1.2rem;
  color: #000;
}

.Info:visited {
  color: #000;
}
